<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 93px; width:411px">
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cód."
          width="67px"
          style="top:9px; left:25px;"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="nombre"
          widget="input"
          searchable
          label="Moneda"
          help="Descripcion"
          placeholder="Descripcion"
          width="270px"
          style="top:9px; left:105px;"
        />
        <field
          name="abreviatura"
          widget="input"
          searchable
          label="abrev"
          help="abreviatura"
          placeholder="abrev"
          width="50px"
          style="top:49px; left:25px;"
        />
        <field
          name="simbolo"
          widget="input"
          searchable
          label="Sim."
          help="simbolo"
          placeholder
          width="30px"
          style="top:49px; left:85px;"
        />
        <!--<field
          name="decimales_significativos"
          widget="input"
          searchable
          label="Decim"
          help="decimales_significativos"
          placeholder="0"
          width="30px"
          style="top:49px; left:125px;"
        />-->
        <field
          name="fecha_ultimo_cambio"
          widget="input"
          type="date"
          searchable
          label="Fecha"
          help="fecha_ultimo_cambio"
          placeholder="F.U.C"
          width="120px"
          style="top:49px; left:165px;"
        />
        <field
          name="ratio"
          widget="input"
          type="number"
          dec="6"
          searchable
          label="Tipo de cambio"
          help="ratio"
          placeholder="0"
          width="75px"
          style="top:49px; left:300px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Moneda",
      dbAdapter: "moneda",
      primary: "codigo",
      sequence: { name: "moneda" }
    };
  }
};
</script>
